<template>
  <v-row>
    <v-col>
      <s-toolbar label="Soporte Usuarios" />
      <v-card :loading="loading">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <h2>Usuarios SAP Bloqueados</h2>
              <p>Muestra solo usuarios bloqueados de SBO_SUNS</p>
              <v-autocomplete
                class="mt-4"
                item-value="UssUserCode"
                item-text="UssUserCode"
                :items="usersLocked"
                v-model="userToEnabled"
                label="Usuario SAP"
                dense
                hide-details
              >
                <template slot="append-outer">
                  <v-btn icon color="primary" dark @click="loadLocked">
                    <v-icon x-small>fas fa-redo</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
              <v-btn block small color="primary" dark @click="enabledUser" :loading="loadingEnabled">
                Desbloquear
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <h2>Contraseña SAP</h2>
              <p>Mínimo 1 Mayúscula,1 Minúscula, 1 Caracter, 1 número</p>
              <!-- <v-text-field
                label="Compañia"
                v-model="UssCompany"
                hide-details=""
              /> -->
              <v-autocomplete
                item-value="UssUserID"
                item-text="UssUserCode"
                :items="usersSAP"
                v-model="user"
                label="Usuario SAP"
                hide-details
                dense
                return-object
                class="mt-4"
              >
                <template slot="append-outer">
                  <v-btn icon color="primary" dark @click="loadUserSAP">
                    <v-icon x-small>fas fa-redo</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
              <v-text-field label="Contraseña" v-model="UssPassword" />
              <v-btn block small color="primary" dark @click="changePassword" :loading="loadingChangePassword">
                Cambiar contraseña
              </v-btn>
              <v-btn class="mt-2" block small @click="mustChangePassword" :loading="loadingMustChgPwd">
                Cambiar contraseña próxima conexión
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <h2>Contraseña AddOn</h2>
              <p>
                Colocará el mismo usuario en minúscula,pedirá que la cambie en la proxima conexión
              </p>
              <v-autocomplete
                item-value="UsrName"
                item-text="UsrName"
                :items="usersAddon"
                v-model="userAddon"
                label="Usuario AddOn"
                hide-details
                dense
                return-object
                class="mt-4"
              >
                <template slot="append-outer">
                  <v-btn icon color="primary" dark @click="loadUserAddon">
                    <v-icon x-small>fas fa-redo</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
              <v-btn block small color="primary" dark @click="changePasswordAddon">
                Restaurar contraseña
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <h2>Contraseña SDR</h2>
              <p>
                Colocará el mismo usuario en minúscula,pedirá que la cambie en la proxima conexión
              </p>
              <v-autocomplete
                item-value="UsrName"
                item-text="UsrName"
                :items="usersSDR"
                v-model="userSDR"
                label="Usuario SDR"
                hide-details
                dense
                return-object
                class="mt-4"
              >
                <template slot="append-outer">
                  <v-btn icon color="primary" dark @click="loadUserSDR">
                    <v-icon x-small>fas fa-redo</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
              <v-btn block small color="primary" dark @click="changePasswordSDR" hide-details>
                Restaurar contraseña
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title primary-title>
          <h3>Soporte SAP</h3>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" sm="6" md="3">
            <h2>Registra Tipo Cambio</h2>
            <p>
              Tipos de cambio de página SBS, se registrarán en la fecha digitada
            </p>
            <v-data-table :items="exchangeList" :headers="exchangeConf.headers" dense hide-default-footer />
            <s-date v-model="exchangeDate" :outlined="false" />

            <v-btn block small color="primary" dark @click="exchangeRateSave" class="mt-2">
              Registrar
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import _sUserSAP from "@/services/Security/UserSAPService.js";
import _sOtherSupport from "@/services/Security/OtherSupportService.js";

export default {
  data: () => ({
    loading: false,
    loadingEnabled: false,
    loadingChangePassword: false,
    loadingMustChgPwd: false,
    usersLocked: [],
    usersSAP: [],
    userToEnabled: "",
    user: {},
    UssPassword: "123..Abc",
    UssCompany: "SBO_SUNS",
    userAddon: {},
    usersAddon: [],
    userSDR: {},
    usersSDR: [],
    menu1: false,
    exchangeList: [],
    exchangeConf: {
      headers: [
        {
          text: "Moneda",
          value: "name",
          sortable: false,
          align: "center",
        },
        {
          text: "Valor",
          value: "value",
          sortable: false,
          align: "end",
        },
      ],
    },
    exchangeDate: {},
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadLocked();
      this.loadUserSAP();
      this.loadUserAddon();
      this.loadUserSDR();
      this.loadExchangeRate();
      this.exchangeDate = this.$moment().format(this.$const.FormatDateMoment);
    },
    loadLocked() {
      this.loading = true;

      _sUserSAP.listLocked(this.$fun.getUserID()).then((r) => {
        this.usersLocked = r.data;
        this.loading = false;
      });
    },
    loadUserSAP() {
      this.loading = true;

      _sUserSAP.list(this.$fun.getUserID()).then((r) => {
        this.usersSAP = r.data;
        this.loading = false;
      });
    },
    loadUserAddon() {
      this.loading = true;
      var obj = { SecStatus: 1 }; //addon 1 2 SDR
      _sOtherSupport.list(obj, this.$fun.getUserID()).then((r) => {
        this.usersAddon = r.data;
        this.loading = false;
      });
    },
    loadUserSDR() {
      this.loading = true;
      var obj = { SecStatus: 2 }; //addon 1 2 SDR
      _sOtherSupport.list(obj, this.$fun.getUserID()).then((r) => {
        this.usersSDR = r.data;
        this.loading = false;
      });
    },
    validateEnabled(uss) {
      let isValid;
      let message;

      isValid = uss.UssUserCode.length > 0;
      if (!isValid) message = "Seleccione Usuario SAP";

      if (!isValid) this.$fun.alert(message, "warning");
      return isValid;
    },
    enabledUser() {
      this.loadingEnabled = true;
      var uss = { UssUserCode: this.userToEnabled };

      if (this.validateEnabled(uss)) {
        _sUserSAP.enabled(uss, this.$fun.getUserID()).then(
          (r) => {
            this.$fun.alert("Usuario " + this.userToEnabled + " Desbloqueado", "success");
            this.loadLocked();
            this.loadUserSAP();
            this.loadingEnabled = false;
          },
          (e) => {
            this.loadingEnabled = false;
          }
        );
      } else this.loadingEnabled = false;
    },
    validateChangePassword(uss) {
      let isValid;
      let message;

      isValid = uss.UssUserID > 0;
      if (!isValid) message = "Seleccione Usuario SAP";
      else {
        isValid = uss.UssPassword.length > 0;
        if (!isValid) message = "Digite una contraseña Usuario SAP";
        else {
          isValid = uss.UssCompany.length > 0;
          if (!isValid) message = "Digite una compañia (SBO_SUNS base real)";
        }
      }
      if (!isValid) this.$fun.alert(message, "warning");
      return isValid;
    },
    changePassword() {
      this.loadingChangePassword = true;
      var uss = {
        UssUserID: this.user.UssUserID,
        UssPassword: this.UssPassword,
        UssCompany: this.UssCompany,
      };

      if (this.validateChangePassword(uss)) {
        _sUserSAP.changePassword(uss, this.$fun.getUserID()).then(
          (r) => {
            this.$fun.alert("Cambio de contraseña de " + this.user.UssUserCode + " Correcto", "success");
            this.UssPassword = "123..Abc";
            this.UssCompany = "SBO_SUNS";
            this.loadingChangePassword = false;
          },
          (e) => {
            this.loadingChangePassword = false;
          }
        );
      } else this.loadingChangePassword = false;
    },
    mustChangePassword() {
      this.loadingMustChgPwd = true;
      var uss = { UssUserCode: this.user.UssUserCode };

      if (this.validateEnabled(uss)) {
        _sUserSAP.mustChangePassword(uss, this.$fun.getUserID()).then(
          (r) => {
            this.$fun.alert("Actualización a usuario " + this.user.UssUserCode + " Correcta", "success");
            this.loadingMustChgPwd = false;
          },
          (e) => {
            this.loadingMustChgPwd = false;
          }
        );
      } else this.loadingMustChgPwd = false;
    },
    validateChangePasswordAddon(uss) {
      let isValid;
      let message;

      isValid = uss.UssUsrName.length > 0;
      if (!isValid) message = "Seleccione Usuario Addon";

      if (!isValid) this.$fun.alert(message, "warning");
      return isValid;
    },
    changePasswordAddon() {
      var uss = {
        UssUsrName: this.userAddon.UsrName,
        UssPassword: this.userAddon.UsrName.toLowerCase(),
        SecStatus: 1, //1 Addon 2 SDR
      };

      if (this.validateChangePasswordAddon(uss)) {
        _sOtherSupport.changePassword(uss, this.$fun.getUserID()).then((r) => {
          this.$fun.alert("Cambio de contraseña de " + this.userAddon.UsrName + " Correcto", "success");
        });
      }
    },
    validateChangePasswordSDR(uss) {
      let isValid;
      let message;

      isValid = uss.UsrName.length > 0;
      if (!isValid) message = "Seleccione Usuario SDR";

      if (!isValid) this.$fun.alert(message, "warning");
      return isValid;
    },
    changePasswordSDR() {
      var uss = {
        UsrName: this.userSDR.UsrName,
        UsrPassword: this.userSDR.UsrName.toLowerCase(),
        SecStatus: 2, //1 Addon 2 SDR
      };
      if (this.validateChangePasswordSDR(uss)) {
        _sOtherSupport.changePassword(uss, this.$fun.getUserID()).then((r) => {
          this.$fun.alert("Cambio de contraseña de " + this.userSDR.UsrName + " Correcto", "success");
        });
      }
    },
    //Tipo de Cambio
    loadExchangeRate() {
      this.loading = true;

      _sOtherSupport.exchangeRateList(this.$fun.getUserID()).then((r) => {
        this.exchangeList.push({ name: "USD", value: r.data.RateUSD });
        this.exchangeList.push({ name: "EUR", value: r.data.RateEUR });
        this.exchangeList.push({ name: "LIB", value: r.data.RateLIB });
        this.loading = false;
      });
    },
    validateExchangeRate(date) {
      let isValid;
      let message;

      isValid = date != undefined;
      if (!isValid) message = "Seleccione Fecha";

      if (!isValid) this.$fun.alert(message, "warning");
      return isValid;
    },
    exchangeRateSave() {
      if (this.validateExchangeRate(this.exchangeDate)) {
        _sOtherSupport.exchangeRateSave(this.exchangeDate, this.$fun.getUserID()).then((r) => {
          this.$fun.alert("Se registro tipo de cambio", "success");
        });
      }
    },
  },
};
</script>
